.leaderboard_topbar {
  width: 1140px;
  height: 70px;
  margin-top: 50px;
  padding: 10px 40px;
  background-image: url('/src/assets/HOME/03-leaderboard-1-top_bar.webp');
}

.blast_avatar {
  width: 70px;
  height: 70px;
  background-image: url('/src/assets/HOME/03-leaderboard-2-pfp-1-BLAST.webp');
}

.entry_0 {
  width: 479px;
  height: 70px;
  padding: 25px 60px;
  background-image: url('/src/assets/HOME/03-leaderboard-3-top1.webp');
}
.entry_1 {
  width: 479px;
  height: 70px;
  padding: 25px 60px;
  background-image: url('/src/assets/HOME/03-leaderboard-3-top2.webp');
}
.entry_2 {
  width: 479px;
  height: 70px;
  padding: 25px 60px;
  background-image: url('/src/assets/HOME/03-leaderboard-3-top3.webp');
}
.entry_3 {
  width: 479px;
  height: 70px;
  padding: 25px 60px;
  background-image: url('/src/assets/HOME/03-leaderboard-3-top4-10.webp');
}

.flex_between {
  display: flex;
  justify-content: space-between;
}

.prize_pool {
  width: 559px;
  height: 230px;
  padding: 50px 60px;
  background-image: url('/src/assets/HOME/03-leaderboard-4-rewards.webp');
  display: flex;
  flex-direction: column;
}

.grey_txt {
  color: #838383;
  line-height: 10px;
}

.leaderboard_open {
  width: 1140px;
  height: 70px;
  background-image: url('/src/assets/HOME/03-leaderboard-5-bottom_2-open.webp');
  display: flex;
  justify-content: center;
  align-items: center;
}
.closed {
  width: 1140px;
  height: 70px;
  background-image: url('/src/assets/HOME/03-leaderboard-5-bottom-arrow-DOWN.webp');
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 50%;
}
.open {
  width: 1140px;
  height: 70px;
  background-image: url('/src/assets/HOME/03-leaderboard-5-bottom-arrow-UP.webp');
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 50%;
}
.closed:hover {
  background-image: url('/src/assets/HOME/03-leaderboard-5-bottom-arrow-DOWN_HOVER.webp');
  background-repeat: no-repeat;
}
.open:hover {
  background-image: url('/src/assets/HOME/03-leaderboard-5-bottom-arrow-UP_HOVER.webp');
  background-repeat: no-repeat;
}
