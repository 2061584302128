@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&display=swap');

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Kalam', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #020202;
  color: rgb(243, 243, 243);
  /* background-image: url('/src/assets/bg-static.png');
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  background-attachment: fixed; */
}
