.carousel {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: auto;
  overflow: hidden;
  margin-top: 30px;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
}

.carousel img {
  width: 100%;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  width: 100%;
}

.carousel-indicators button {
  background-color: #888;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 5px;
  margin: 10px 5px;
  width: 35px;
}

.carousel-indicators button.active {
  background-color: #fff;
}

.carousel-control-prev,
.carousel-control-next {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-control-prev {
  left: 10px;
}

.carousel-control-next {
  right: 10px;
}
